import { Grid, PaletteMode, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { sxs } from '@utils/mui/sx';

const getSearchBox = (colorVersion: string): SxProps => ({
    backgroundColor: colorVersion === 'dark' ? 'secondary.main' : 'background.default',
    border: 1,
    borderColor: colorVersion === 'dark' ? 'grey.300' : 'transparent',
    borderRadius: 0.5,
    boxShadow: 1,
    height: 56,
    position: 'relative',
});

interface SearchBoxContainerProps {
    children: ReactNode;
    colorVersion?: PaletteMode;
    sx?: SxProps;
}

const SearchBoxContainer: FC<SearchBoxContainerProps> = ({
    children,
    sx = {},
    colorVersion = 'light',
}) => {
    const searchBox = getSearchBox(colorVersion);

    return (
        <Grid
            alignContent="center"
            alignItems="center"
            direction="row"
            id="keep-underbar-card-open"
            sx={sxs([searchBox, sx])}
            container
        >
            {children}
        </Grid>
    );
};

export default SearchBoxContainer;
