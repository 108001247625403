import { LanguageUppercase, LawTheme, TreeDataNode, TreeWithId, TreeContent } from '@livv/models';

/**
 * Recursively prunes (removes) nodes from a data tree (TreeDataNode) based on certain conditions.
 *
 * @param {TreeDataNode | undefined} node - The current node in the tree to inspect.
 * @param {boolean} pruneBySheet - Determines whether pruning should be based on the presence of sheets in nodes.
 * @returns {boolean} - Returns `true` if at least one node or descendant was retained based on the pruning conditions.
 *
 * @remarks
 * A sheet in the application represents a paragraph.
 *
 * Pruning conditions include:
 * - The node must be published (node.isPublished === true).
 * - (Optional) The node must contain at least one sheet if `pruneBySheet` is set to `true`.
 */
const pruneTreeEagerNodeByPublishedAndSheets = (
    node?: TreeDataNode,
    pruneBySheet: boolean = true,
): boolean => {
    if (node?.isPublished) {
        let atLeastOneSheetAtDescendentOrSelf = pruneBySheet ? Boolean(node?.hasSheet) : true;
        if (!node?.children || node.children.length === 0) {
            return atLeastOneSheetAtDescendentOrSelf;
        }

        const childrenToPrune: number[] = [];
        node.children.forEach((child, idx) => {
            const sheetAtDescendent = pruneTreeEagerNodeByPublishedAndSheets(child, pruneBySheet);
            if (!sheetAtDescendent) {
                childrenToPrune.push(idx);
            }
            atLeastOneSheetAtDescendentOrSelf =
                atLeastOneSheetAtDescendentOrSelf || sheetAtDescendent;
        });
        childrenToPrune.reverse().forEach((childIdx) => node.children?.splice(childIdx, 1));

        return atLeastOneSheetAtDescendentOrSelf;
    }
    if (node?.children) {
        node.children = [];
    }

    return false;
};

/**
 * Pruning function based on depth first search.
 * Keeps all nodes with at least one sheet at its descendants.
 */
export const pruneTreeEager = (tree: TreeDataNode[], pruneBySheet = true): TreeDataNode[] =>
    tree.reduce<TreeDataNode[]>((content, currentNode) => {
        if (currentNode.isPublished) {
            pruneTreeEagerNodeByPublishedAndSheets(currentNode, pruneBySheet);
            content.push(currentNode);
        }

        return content;
    }, []);

export const getFirstNodeWithContent = (
    tree: TreeDataNode[],
    isLegitext: boolean,
): TreeDataNode | undefined => {
    if (isLegitext) {
        return tree[0];
    }

    for (const node of tree) {
        if (node.isPublished && (node.hasSheet || node.children?.length)) {
            return node;
        }
    }

    return tree[0];
};

/**
 * Walk through a tree and find all nodes containing searched substring,
 * append a complete path of each found node to foundPaths.
 * `tree` -- tree's entry point an array if top level nodes,
 * `searchTerm` -- string to find,
 * `foundPaths` -- reference to an empty array that will be mutated by the function,
 * `path` -- accumulator to store the traveled path.
 */
export const treeSearch = (
    tree: TreeDataNode[],
    searchTerm: string,
    foundPaths: string[][],
    path: string[] = [],
): void => {
    const searchTermRegExp = new RegExp(searchTerm, 'i');
    tree.forEach((node) => {
        const { title, id, children } = node;
        const currentPath = [...path, id];

        if (searchTermRegExp.test(title)) {
            foundPaths.push(currentPath);
        }
        if (children) {
            treeSearch(children, searchTerm, foundPaths, currentPath);
        }
    });
};

export const buildTreesByThemes = (trees?: TreeWithId[]): Record<LawTheme, TreeWithId[]> => {
    const treesByThemes: Record<LawTheme, TreeWithId[]> = {
        'commercial-law': [],
        'economic-law': [],
        other: [],
        'practical-works': [],
        'procedural-law': [],
    };

    trees?.forEach((tree) => {
        const treeContentParsed = TreeContent.toRaw(tree.content);
        const hasPublishedSheets = pruneTreeEager(treeContentParsed, true).length > 0;
        if (hasPublishedSheets) {
            treesByThemes[tree.theme].push(tree);
        }
    });

    return treesByThemes;
};

/**
 * Sorts an array of trees by language and title.
 *
 * @param {LanguageUppercase} primaryLanguage - The primary language to sort by. Trees with this language will be sorted to the front.
 * @param {TreeWithId[] | undefined} trees - The array of trees to sort. If this parameter is undefined, the function will return undefined.
 * @param {string} lang - The locale to use when comparing titles. Defaults to 'fr'.
 *
 * @returns {TreeWithId[] | undefined} - The sorted array of trees, or undefined if the `trees` parameter was undefined.
 *
 * This function sorts an array of trees in the following order:
 * 1. By language: Trees with the `primaryLanguage` are sorted to the front.
 * 2. By title: If two trees have the same language, they are sorted by their title in alphabetical order according to the `lang` locale.
 */
export const sortTreesByLanguageAndTitle = (
    primaryLanguage: LanguageUppercase,
    trees?: TreeWithId[],
    lang: string = 'fr',
): TreeWithId[] | undefined => {
    if (!trees) {
        return undefined;
    }

    return trees.sort((firstBook, secondBook) => {
        if (firstBook.language === secondBook.language) {
            return firstBook.title.localeCompare(secondBook.title, lang);
        }

        return firstBook.language === primaryLanguage ? -1 : 1;
    });
};
